<template>
  <div>
    <Header
      class="flex"
      :back="true"
      :text="$t('Components.Settings.settings')"
      :title="$t('Components.Settings.personal_status')"
    >
      <h5 class="header-title">
        {{ $t('Components.Settings.personal_status') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card>
        <ul class="status-modal__list">
          <li
            v-for="status in CompanyState.userStatusTypes"
            :key="status.Type"
            @click="setStatus(status)"
          >
            <UserStatusListItem :user-status="status" />
          </li>
        </ul>
        <div>
          <b-form-checkbox
            v-model="UserState.userStatus.IsAuto"
            :disabled="isAuto"
            @change="UserService.setAutoStatus()"
          >
            {{ $t('Components.Settings.automatic') }}
          </b-form-checkbox>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
/* eslint-disable func-names */
/** Components */
import { BFormCheckbox } from 'bootstrap-vue';
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import UserStatusListItem from '@/views/settings/components/user.status.list.item.vue';

/** Services */
import UserService from '@/Services/User/user.service';
import CompanyService from '@/Services/Company/company.service';

/** State */
import UserState from '@/singletons/user.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';

/** Modules */
import i18n from '@/i18n';

export default {
  components: {
    BFormCheckbox,
    UserStatusListItem,
    Header,
    Card,
  },
  data() {
    return {
      i18n,
      CompanyState,
      UserService,
      UserState,
    };
  },
  computed: {
    isAuto() { return UserState.userStatus.IsAuto; },
  },
  async mounted() {
    await CompanyService.getAvailableUserStatuses();
  },
  methods: {
    setStatus(status) {
      UserService.changeStatus(status);
    },
  },
};
</script>

<style lang="scss" scoped>
ul{
  padding: 0;
  li{
  list-style: none;
  }
}
</style>
