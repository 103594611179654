/* eslint-disable vue/prop-name-casing */
/* eslint-disable vue/require-default-prop */
<template>
  <div class="user-status-list-item__container margin margin-bottom margin-medium">
    <div class="user-status-left-part">
      <div
        class="user-status-list-item__user-status"
        :class="iconBackground"
      >
        <SVGIcon
          v-if="userStatus.IconHandle"
          :src="userStatus.IconHandle"
        />
      </div>
      <p> {{ userStatus.Title }} </p>
    </div>
    <div v-if="isCurrent">
      <font-awesome-icon
        v-if="!isAuto"
        icon="check"
      />
      <span v-else>{{ $t('Components.Settings.automatic') }}</span>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import UserState from '@/singletons/user.state.singleton';
import SVGIcon from '@/components/globals/SVGIcon/SVGIcon.vue';

export default {
  components: { SVGIcon, FontAwesomeIcon },
  props: {
    userStatus: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      UserState,
    };
  },
  computed: {
    isAuto() { return UserState.userStatus.IsAuto; },
    isCurrent() {
      return this.UserState?.userStatus?.Type === this.userStatus?.Type;
    },
    iconBackground() {
      return this.userStatus?.BackgroundColor || null;
    },
  },
};
</script>
<style lang="scss">

.user-status-list-item__container{
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  display: flex;
}

.user-status-left-part{
  display: flex;
  gap: 1rem;
  p{
    margin: auto;
  }
}

.user-status-list-item__user-status {
  z-index: 1;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  border-radius: 50%;
  span.icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    color: white;
    fill: white;
    height: 1rem;
  }
  &.green {
    // background-color: #1d9f8f;
    background-color: #0bb251;
    background-color: var(--free-color);
  }

  &.red {
    // background-color: #da1e5e;
    background-color: #c03f5d;
    background-color: var(--occupied-color);
  }

  &.blue {
    // background-color: #206173;
    background-color: #5ca0b7;
    background-color: var(--color-resource-not-in-filter-old);
  }

  &.yellow {
    // background-color: #f79321;
    background-color: #f79321;
    background-color: var(--away-color);
  }

  &.gray {
    // background-color: #e1e3e4;
    background-color: #969696;
    background-color: var(--color-resource-other);
  }
}

</style>
